import { template as template_12d41159bad8456399ce01c4d3fa3518 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_12d41159bad8456399ce01c4d3fa3518(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
