import { template as template_b2855ab124e44aab888803cd55b65f6f } from "@ember/template-compiler";
import { gt } from "truth-helpers";
import icon from "discourse/helpers/d-icon";
import number from "discourse/helpers/number";
const LikesCell = template_b2855ab124e44aab888803cd55b65f6f(`
  <td class="num likes topic-list-data">
    {{#if (gt @topic.like_count 0)}}
      <a href={{@topic.summaryUrl}}>
        {{number @topic.like_count}}
        {{icon "heart"}}
      </a>
    {{/if}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
