import { template as template_8dd203ae168a4cb9a64de3e4592a9a3f } from "@ember/template-compiler";
const FKLabel = template_8dd203ae168a4cb9a64de3e4592a9a3f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
