import { template as template_d8a98764f66a4fa18b2df56f69d621c5 } from "@ember/template-compiler";
const SidebarSectionMessage = template_d8a98764f66a4fa18b2df56f69d621c5(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
